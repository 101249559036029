/**
 * External dependencies
 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Internal dependencies
 */
import Blockquote from '../components/blockquote';
import Hero from '../components/hero';
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ButtonGroup from '../components/button-group';
import Button from '../components/button';
import ClientList from '../components/client-list';
import ShapedImage from '../components/shaped-image';
import StripeForm from '../components/stripe-form';
import InternalLink from '../components/internal-link';
import { isLoggedIn, getCurrentUser } from '../lib/auth';
import OGImage from '../images/mentorship-og-image.png';
import { getUrlParameter } from '../lib/stripeUtils';

function MentorshipPage( props ) {
    const [ planOption, setPlanOption ] = useState( 'monthly' );
    const [ hasMounted, setHasMounted ] = useState( false );

    const isOffer = getUrlParameter( 'isOffer' ) || false;
    const normalPrice = 149;
    const price = isOffer || 149;
    const currentUser = getCurrentUser();
    const isClosed = true;

    useEffect( () => {
        setHasMounted( true );
    } );

    const handlePlanOptionChange = ( event ) => {
        setPlanOption( event.target.value );
    };

    const { location } = props;

    return (
        <Layout
            location={ location }
            ctaText="Sign Up"
            ctaAnchor="#enrol-now"
            noSignUpForm
            hideHeader
            hideLinks
        >
            <SEO
                title="The Mentorship Programme For Freelancers"
                description="I can teach you how to get paid what you're worth, work smarter and find balance on my specialist mentorship programme for freelancers."
                imageAlt="The Mentorship Programme For Freelancers"
                imageUrl={ `https://www.tomhirst.com${OGImage}` }
            />
            <Hero
                imageName="tom-hirst-headshot.png"
                imageAlt="Tom Hirst, your freelancing mentor."
                imageCaption="Tom Hirst, your freelancing mentor."
                isCentered
                noHeader
                standardHeight
            >
                <h1>The Mentorship Programme<br />For Freelancers</h1>
                <p className="lead" style={ { maxWidth: '85%' } }>
                    I can teach <u>you</u> how to get paid what you&apos;re worth, work
                    smarter and find balance on my specialist mentorship programme for
                    freelancers.
                </p>
                <ButtonGroup>
                    <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                    <Button to="#how-does-it-work" icon="arrow-down" text="How does it work?" anchor />
                </ButtonGroup>
            </Hero>
            <Section id="how-does-it-work" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>How Does The Mentorship Programme For Freelancers Work?</h2>
                            <p>On a one-to-one basis I teach people how to be better freelancers by email.</p>
                            <p>Students also get access to my purpose made Slack community for freelancers,
                                adding another layer of valuable support to the programme.</p>
                            <p>While books and courses offer generic insight, The Mentorship
                                Programme For Freelancers is a service tailor made for <u>you</u>.</p>
                            <p>Here&apos;s a breakdown of the process we&apos;ll follow together on the programme:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <h3>
                                    <span className="number-circle">1 </span>
                                    Understanding
                                </h3>
                                <p>
                                    I&apos;ll <em>get to know you</em>; your current situation, what your skills are
                                    and what you&apos;re struggling with right now.
                                    This will allow me to deliver specific actionable advice for your development.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <h3>
                                    <span className="number-circle">2 </span>
                                    Direction
                                </h3>
                                <p>
                                    You&apos;ll get <em>clear direction</em> from me on what to do to improve
                                    your freelancing career. I&apos;ll teach you exactly what has brought me
                                    success as a freelancer over the last decade.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <h3>
                                    <span className="number-circle">3 </span>
                                    Support
                                </h3>
                                <p>
                                    As you progress on your journey, I will be there to support you <em>every step of
                                    the way</em>.
                                    No problem is too small or too large.
                                    You can be confident moving forward with me on your team.
                                </p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <h3>
                                    <span className="number-circle">4 </span>
                                    Success
                                </h3>
                                <p>
                                    You&apos;ll be a <em>better freelancer</em>. You&apos;ll be further ahead faster
                                    by having the knowledge to get paid what you&apos;re worth, work smarter and
                                    find balance through freelancing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                                <Button to="#who-is-it-for" icon="arrow-down" text="Is it for me?" color="grey"
                                        anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="who-is-it-for" color="light-grey" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>Who Is The Freelancing Mentorship Programme For Exactly?</h2>
                            <p>
                                Enrol on The Mentorship Programme For Freelancers if:
                            </p>
                            <ul className="check-list">
                                <li><FontAwesomeIcon icon="check-circle" />You&apos;re thinking about, or in the
                                    process of, quitting your job to go freelance
                                </li>
                                <li><FontAwesomeIcon icon="check-circle" />You want to hit the ground running as a
                                    new freelancer
                                </li>
                                <li><FontAwesomeIcon icon="check-circle" />Your freelancing career has stalled and
                                    you want to turn things around
                                </li>
                                <li><FontAwesomeIcon icon="check-circle" />You&apos;re a steady freelancer who wants
                                    to move things up a gear
                                </li>
                                <li><FontAwesomeIcon icon="check-circle" />You&apos;re a seasoned freelancer who wants
                                    to realign
                                </li>
                                <li><FontAwesomeIcon icon="check-circle" />You have an interest in freelancing,
                                    consulting or running a solo-business
                                </li>
                            </ul>
                            <p>Under any of these circumstances, The Mentorship Programme For Freelancers is
                                for <u>you</u>.</p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                                <Button to="#what-will-i-learn" icon="arrow-down" text="What will I learn?" color="grey"
                                        anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="what-will-i-learn" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>What Will I Learn?</h2>
                            <p>You&apos;ll be armed with all of the tools you need for life as a successful
                                freelancer. Including, but not limited to:</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <ul className="check-list benefits">
                                    <li><FontAwesomeIcon icon="play-circle" />Setting up</li>
                                    <li><FontAwesomeIcon icon="comment-dollar" />Marketing yourself</li>
                                    <li><FontAwesomeIcon icon="users" />Audience building</li>
                                    <li><FontAwesomeIcon icon="network-wired" />Remote and local networking</li>
                                    <li><FontAwesomeIcon icon="handshake" />Client communications and negotiation
                                    </li>
                                    <li><FontAwesomeIcon icon="tags" />Pricing your services</li>
                                    <li><FontAwesomeIcon icon="trophy" />Winning higher value projects</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6">
                            <div className="card">
                                <ul className="check-list benefits">
                                    <li><FontAwesomeIcon icon="smile-beam" />Obtaining fulfilling work</li>
                                    <li><FontAwesomeIcon icon="hand-holding-usd" />Getting paid well and on time</li>
                                    <li><FontAwesomeIcon icon="calendar-check" />Finding focus</li>
                                    <li><FontAwesomeIcon icon="clock" />Being time efficient</li>
                                    <li><FontAwesomeIcon icon="heart" />Prioritising self-care</li>
                                    <li><FontAwesomeIcon icon="file-invoice-dollar" />Running a solo-business</li>
                                    <li><FontAwesomeIcon icon="balance-scale" />Maintaining work/life balance</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                                <Button to="#what-can-i-achieve" icon="arrow-down" text="What can I achieve?"
                                        color="grey" anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="what-can-i-achieve" color="light-grey" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>What Results Can I Achieve?</h2>
                            <p>You&apos;ll accelerate your freelancing career and your network will be expanded.</p>
                            <p>Through my experience, and that of your peers in The Mentorship Programme For Freelancers
                                Slack community, you&apos;ll get further ahead,
                                faster.</p>
                            <p>The ultimate outcome I want each person enrolled on The Mentorship Programme For
                                Freelancers to arrive at is:</p>
                            <p className=""><em>A prosperous career alongside a well balanced life achieved through
                                freelancing</em>.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-lg-4">
                            <div className="card">
                                <ul className="check-list benefits large">
                                    <li>
                                        <FontAwesomeIcon icon="check-circle" />Level up your freelancing skills faster
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card">
                                <ul className="check-list benefits large">
                                    <li><FontAwesomeIcon icon="check-circle" />Gain access to a bigger network</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card">
                                <ul className="check-list benefits large">
                                    <li><FontAwesomeIcon icon="check-circle" />Never feel like you&apos;re stuck again
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <p>Enrolment for the long-term is encouraged to truly hone your skills and build
                                authentic connections.</p>
                            <p>Results will <u>not</u> be instant.</p>
                            <p>
                                The Mentorship Programme For Freelancers is an investment in your future that
                                requires continued effort and commitment.
                            </p>
                            <p>
                                Only those who are serious and realistic about achieving a successful career in
                                freelancing need enrol.
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                                <Button to="#the-mentor" icon="arrow-down" text="Who's my mentor?" color="grey"
                                        anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="the-mentor" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>Why Should You Choose Tom Hirst To Be Your Freelancing Mentor?</h2>
                            <ShapedImage
                                name="th-and-sh.jpg"
                                alt="Tom Hirst with Steph Hirst in Spain."
                                pullLeft
                                small
                            />
                            <p>My story as a freelancer began over ten years ago.</p>
                            <p>Starting out as a web developer I built myself up from nothing.</p>
                            <p>Now I consistently make a great living from my freelancing business each year.</p>
                            <p>
                                I do this by working from home, or wherever I choose, four to five days per week for
                                companies around the world.
                            </p>
                            <p>
                                All with the freedom to enjoy quality time with my family, have an active social
                                life, travel at my leisure and take care of my health.
                            </p>
                            <ClientList topRowOnly />
                            <ShapedImage
                                name="tom-hirst-and-rh-square.jpg"
                                alt="Tom Hirst in Dubai."
                                pullRight
                                flipped
                                small
                            />
                            <p>
                                But I didn&apos;t always have great clients who pay well, a flexible work
                                routine and control of my day-to-day life.
                            </p>
                            <p>A lot has happened between then and now and I&apos;m here to share it all with you
                                through The Mentorship Programme For Freelancers.</p>
                            <p>
                                I&apos;m confident that what I teach works because the life I&apos;ve built through
                                freelancing is the proof.
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                                <Button to="#why-now" icon="arrow-down" text="Why do I need this now?" color="grey"
                                        anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="why-now" halfPadding color="light-grey">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>Why Do I Need To Enrol Now?</h2>
                            <p>The best time to start moving your freelancing career forward was yesterday.</p>
                            <p>The second best time is today.</p>
                            <p>Stop using guesswork to get ahead - give yourself the best chance of success by
                                learning from someone who has been there, done it and got the t-shirt.</p>
                            <p>There are limited positions available on The Mentorship Programme For Freelancers at
                                any one time.</p>
                            <p>Let&apos;s start making you a better freelancer <u>now</u> before your slot is filled.
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-right" text="Enrol now!" color="green" anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            { isClosed ? (
                <>
                    <Section id="enrol-now" color="green" narrow>
                        <h2>Sorry, we&apos;re full.</h2>
                        <p>Unfortunately, there are no further places available on The Mentorship Programme For Freelancers at this time.</p>
                        <Button to="mailto:hello@tomhirst.com?subject=Mentorship waiting list&body=Please add me to The Mentorship Programme For Freelancers waiting list." icon="arrow-right" text="Join the waiting list" color="grey" anchor />
                        <p style={ { marginTop: -20 }}><em>You&apos;ll be notified as soon as there&apos;s space!</em></p>
                        <p><strong>Please note</strong>: This is a <u>paid programme</u> requiring <u>time and effort</u>.</p>
                    </Section>
                </>
            ) : (
                <>
                    { hasMounted && (
                        <Section id="enrol-now" color="green">
                            <div className="container">
                                { ! isLoggedIn() ? (
                                    <div className="row">
                                        <div className="col-12 col-lg-6">
                                            <h2 className="no-margin">It&apos;s Time To Enrol</h2>
                                            <p className="lead">
                                                { isOffer && <del>&pound;{ normalPrice }</del> }
                                                { planOption === 'monthly' ? (
                                                    `£${price} per month. Cancel at anytime.`
                                                ) : (
                                                    `£999 per year. Cancel at anytime.`
                                                ) }

                                            </p>
                                            { isOffer && (
                                                <p><strong>Enrol now to get your first 3 months for £{ price }!</strong>
                                                </p>
                                            ) }
                                            <p>As soon as you fill out the form and click "Enrol now!" here&apos;s what will
                                                happen:</p>
                                            <ul className="check-list numbers">
                                                <li><span className="number-circle">1 </span> <span>You&apos;ll get instant <u>one-on-one access</u> to me</span>
                                                </li>
                                                <li><span className="number-circle">2 </span>
                                                    <span>I&apos;ll send you an <u>invite</u> to The Mentorship Programme For Freelancers Slack community</span>
                                                </li>
                                                <li><span className="number-circle">3 </span> <span>You&apos;ll receive a short questionnaire that allows me to <u>get to know you</u></span>
                                                </li>
                                                <li><span className="number-circle">4 </span> <span>I&apos;ll use this information to send you an initial roadmap detailing the <u>best place for you to start</u></span>
                                                </li>
                                                <li><span className="number-circle">5 </span> <span>We&apos;ll move your freelancing career <u>forward together</u></span>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-12 col-lg-6">
                                            <div className="switcher">
                                                <button type="button"
                                                        className={ planOption === 'monthly' ? 'switcher-option current' : 'switcher-option' }
                                                        value="monthly" onClick={ handlePlanOptionChange }>
                                                    Monthly
                                                </button>
                                                <button type="button"
                                                        className={ planOption === 'yearly' ? 'switcher-option current' : 'switcher-option' }
                                                        value="yearly" onClick={ handlePlanOptionChange }>
                                                    Yearly
                                                </button>
                                                { planOption === 'monthly' ? (
                                                    <span className="price-label">£{ price } per month</span>
                                                ) : (
                                                    <span className="price-label">£999 per year <em className="hide-mobile">(Save £789!)</em></span>
                                                ) }
                                            </div>
                                            <StripeForm isOffer={ isOffer } planOption={ planOption } />
                                            <small>
                                                I do not store any credit card information. All transactions are handled by <a
                                                href="https://stripe.com/"
                                                target="_blank"
                                            >Stripe
                                            </a>.<br />
                                                { isOffer ? (
                                                    <>
                                                        On successful enrolment you&apos;ll
                                                        pay &pound;{ planOption === 'monthly' ? price : 999 } straight
                                                        away, with
                                                        a further
                                                        &pound;{ price } for the next two months,
                                                        then &pound;{ normalPrice } each month until you
                                                        cancel.
                                                    </>
                                                ) : (
                                                    <>
                                                        On successful enrolment you&apos;ll
                                                        pay &pound;{ planOption === 'monthly' ? price : 999 } straight
                                                        away, with
                                                        a further
                                                        &pound;{ planOption === 'monthly' ? price : 999 } each { planOption === 'monthly' ? 'month' : 'year' } until
                                                        you cancel.
                                                    </>
                                                ) } No refunds will be offered. You can manage your account by logging in with
                                                your chosen email address and password here: <InternalLink to="/account/">manage
                                                account
                                            </InternalLink>.
                                            </small>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="row">
                                        <div className="col-12 offset-lg-2 col-lg-8">
                                            <h2 className="no-margin">Hi, { currentUser && currentUser.name }!</h2>
                                            <p className="lead">It&apos;s awesome to be working with you.</p>
                                            <ButtonGroup>
                                                <Button
                                                    style={ { marginBottom: '0' } }
                                                    to="/account/"
                                                    icon="arrow-right"
                                                    text="Your account"
                                                    color="grey"
                                                />
                                            </ButtonGroup>
                                        </div>
                                    </div>
                                ) }
                            </div>
                        </Section>
                    ) }
                </>
            ) }
            <Section id="what-do-people-say" halfPadding color="light-grey">
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <h2>What do people say about this?</h2>
                            <p>Here&apos;s what some of the people who have enrolled on The Mentorship Programme For
                                Freelancers say:</p>
                            <Blockquote
                                quote="Through Tom’s mentorship I’ve been able to earn a lot more without over-working myself."
                                cite="Jordan Gilroy, freelance graphic designer"
                                imageName="jg-headshot.jpg"
                                imageAlt="Jordan Gilroy"
                                largeQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="Tom asks those 'how come I didn't think of that' questions that actually make a difference."
                                cite="Alexander Bach, digital strategist"
                                imageName="ab-headshot.jpg"
                                imageAlt="Alexander Bach"
                                largeQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="I highly recommend Tom's services to any freelancer wanting to hit the ground running."
                                cite="Araminta Robertson, freelance copywriter"
                                imageName="ar2-headshot.jpg"
                                imageAlt="Araminta Robertson"
                                mediumQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="I'm more focused and have made big changes to my working life."
                                cite="Richard Voller, digital marketing consultant"
                                imageName="rv-headshot.jpg"
                                imageAlt="Richard Voller"
                                largeQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="Tom made me see that I was worth more than I sold myself for. Sharing experience is very valuable and Tom is really good at it. Recommended."
                                cite="Gaya Kessler, freelance JavaScript developer"
                                imageName="gk-headshot.jpg"
                                imageAlt="Gaya Kessler"
                                mediumQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="Tom helped me to focus, take action and understand the importance of being consistent. I highly recommend his mentorship programme."
                                cite="Abi Sonpar, SEO consultant"
                                imageName="as-headshot.jpg"
                                imageAlt="Abi Sonpar"
                                mediumQuote
                                boxedWhite
                            />
                            <Blockquote
                                quote="Tom is great at explaining things in a helpful and insightful way. Finding a good mentor is one of the best business decisions I have ever made."
                                cite="Sarah Jee Watson, freelance UI designer & UX consultant"
                                imageName="sjw-headshot.jpg"
                                imageAlt="Sarah Jee Watson"
                                mediumQuote
                                boxedWhite
                            />
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <p><u>You</u> could be the next person to see results by enrolling on The Mentorship
                                Programme For Freelancers.</p>
                            <p>Enrol now and we&apos;ll start making progress today.</p>
                            <ButtonGroup>
                                <Button to="#enrol-now" icon="arrow-up" text="Enrol now!" color="green" anchor />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
            <Section id="10-steps" halfPadding>
                <div className="container">
                    <div className="row d-flex justify-content-center">
                        <div className="col-12 col-lg-8">
                            <ShapedImage
                                name="10-steps-cover-square.jpg"
                                alt="10 Steps To Becoming A Better Freelancer by Tom Hirst"
                                pullRight
                                flipped
                                square
                                style={ { maxWidth: '60%' } }
                            />
                            <h2>Not Quite Convinced?</h2>
                            <p>I have a <u>free sample</u> for you.</p>
                            <p>You can read about the basic core principals that I follow in my eBook, <em>10 Steps To
                                Becoming A Better Freelancer</em>.</p>
                            <p>Download it now to see if The Mentorship Programme For Freelancers would be a good
                                fit.</p>
                            <ButtonGroup>
                                <Button to="https://tomhir.st/10-steps" icon="download" text="Download my FREE eBook"
                                        color="green" external />
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </Section>
        </Layout>
    );
}

MentorshipPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

MentorshipPage.defaultProps = {
    location: {},
};

export default MentorshipPage;
